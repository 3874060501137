import React, { ReactElement } from 'react'
import { Link, graphql, PageProps } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TriangleSeparator from '../components/TriangleSeparator'

import Back from '../icons/back.svg'
import Next from '../icons/next.svg'
import Textbook from '../icons/textbook.svg'
import { BlogPost, SiteMetaData } from '../utils/types'

interface Data {
  site: SiteMetaData
  markdownRemark: BlogPost
}

interface PageContext {
  previous: BlogPost
  next: BlogPost
}

const BlogPostTemplate = ({
  data,
  pageContext,
}: PageProps<Data, PageContext>): ReactElement => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  const words = post.fields.readingTime.words.toLocaleString()
  const minutes = Math.round(post.fields.readingTime.minutes)

  const readTime = `Approx. ${minutes} minute${minutes === 1 ? '' : 's'}`

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        slug={post.fields.slug}
        image={
          post.frontmatter.cover
            ? post.frontmatter.cover.childImageSharp.fluid.src
            : '/socialBgSmall.png'
        }
      />

      <TriangleSeparator />

      <article className="container mx-auto md:px-24">
        <header className="mb-4">
          <time
            dateTime={post.frontmatter.isoDate}
            className="text-sm font-bold"
          >
            {post.frontmatter.date}
          </time>

          <h1 className="header2">{post.frontmatter.title}</h1>
          <span className="text-lg">{post.frontmatter.description}</span>

          <div className="text-sm mt-4 ml-2 align-baseline">
            <div className="inline-block h-8 w-8 mr-2">
              <Textbook />
            </div>
            {words} words &mdash; {readTime}
          </div>
        </header>

        {post.frontmatter.cover && (
          <figure className="my-10">
            <Img
              alt={post.frontmatter.imageAltText}
              fluid={{
                ...post.frontmatter.cover.childImageSharp.fluid,
                aspectRatio: 2 / 1,
              }}
              objectPosition={post.frontmatter.cropFocus || '50% 50%'}
            />
            <figcaption
              className="text-xs mt-2"
              dangerouslySetInnerHTML={{ __html: post.fields.imageCredit }}
            />
          </figure>
        )}

        <section
          className="post__body"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>

      {(previous || next) && (
        <nav className="container mx-auto mt-16 md:px-24">
          <hr className="border-light-gray" />
          <ul className="my-4 grid grid-cols-2 gap-4 grid-eq-height">
            <li key="previous-link">
              {previous && (
                <Link
                  to={previous.fields.slug}
                  rel="prev"
                  className="flex h-full items-center"
                >
                  <div className="h-7 w-5 mr-2 flex-shrink-0">
                    <Back />
                  </div>
                  <span className="text-sm">{previous.frontmatter.title}</span>
                </Link>
              )}
            </li>
            <li key="next-link">
              {next && (
                <Link
                  to={next.fields.slug}
                  rel="next"
                  className="flex justify-end h-full items-center"
                >
                  <span className="text-sm text-right">
                    {next.frontmatter.title}
                  </span>
                  <div className="h-7 w-5 ml-2 flex-shrink-0">
                    <Next />
                  </div>
                </Link>
              )}
            </li>
          </ul>
        </nav>
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      rawMarkdownBody
      fields {
        slug
        imageCredit
        readingTime {
          minutes
          words
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        isoDate: date
        description
        imageAltText
        cropFocus
        cover {
          childImageSharp {
            fluid(cropFocus: ATTENTION) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
